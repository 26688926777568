<script setup lang="ts">
// icons
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiFilterBooleanProps {
  label?: string;
  name: string;
  value: boolean;
}
const props = withDefaults(defineProps<CiFilterBooleanProps>(), {
  label: '',
});

const emit = defineEmits<{
  input: [value: boolean];
}>();

/********************
 * FUNCTIONS         *
 ********************/
function toggle() {
  emit('input', !props.value);
}
</script>

<template>
  <div
    :class="[value ? 'border-primary' : 'border-gray-30']"
    class="filter-boolean text-inherit relative flex break-inside-avoid flex-row items-center overflow-hidden hyphens-auto rounded-md border bg-transparent hover:bg-black/5"
    :title="label"
  >
    <label
      :for="name"
      class="m-0 line-clamp-3 flex shrink grow basis-auto cursor-pointer select-none flex-row items-center break-words py-2 pl-4 pr-8 text-sm"
    ><slot /><span>{{ label }}</span></label>
    <input
      :id="name"
      :name="name"
      class="hidden"
      type="checkbox"
      @change="toggle"
    >
    <CiAwesomeIcon
      v-if="props.value"
      :icon="faCheck"
      class="absolute right-0 top-1/2 mx-2 -translate-y-1/2 fill-primary"
      ratio="0.75"
    />
  </div>
</template>

<style></style>
